<template>
  <div class="w-96">
    <form @submit.prevent="onSubmit">

      <PAlert
        v-if="success"
        class="text-center"
        type="success"
        v-html="$tk('ForgotPassword.ConfirmText')"
      ></PAlert>
      <p
        v-if="success"
        class="mx-auto mt-4"
      >
        <router-link
          to="login"
          class="text-lg underline hover:no-underline hover:text-green-600"
        >
          <FontAwesomeIcon :icon="['far', 'arrow-left']" /> Tilbake til Login
        </router-link>

      </p>

      <div v-else>

        <PAlert
          v-if="error"
          class="text-center"
          type="error"
          v-html="error.message"
        ></PAlert>

        <PAlert
          v-else
          type="info"
          v-html="$tk('ForgotPassword.InfoText')"
        ></PAlert>

        <PInput
          class="mt-4"
          :label="$tk('Common.General.Username')"
          v-model="username"
        />

        <div class="flex items-center mt-8 space-x-2">

          <PButton
            color="primary"
            type="submit"
            :disabled="!canSubmit"
            :loading="isSubmitting"
          >
            <span v-html="$tk('ForgotPassword.SendRequest')"></span>
          </PButton>

          <router-link
            to="login"
            class="text-sm hover:underline"
            v-html="$tk('Common.Actions.Cancel')"
          ></router-link>

        </div>
      </div>
    </form>
  </div>
</template>

<script>
import http from "@/http"
export default {
  name: "ForgotPassword",
  data() {
    return {
      username: "",
      isSubmitting: false,
      error: null,
      success: false,
      url: ""
    }
  },
  computed: {
    canSubmit() {
      return this.username !== ""
    }
  },
  methods: {
    async onSubmit() {
      this.error = null

      const options = {
        userLoginName: this.username,
        url: this.url
      }

      try {
        await http.post("UserPasswordRequest", options)
        this.success = true
      } catch (error) {
        console.log("!!!Error sending new password:", error)
        this.error = {
          message: error.reason ? error.reason : this.$tk('ForgotPassword.ErrorSendingLink')
        }
        this.success = false
      }

    }
  },
  created() {
    this.url = `${location.protocol}//${location.host}/reset-password`
    //this.$refs.input.focus() TODO: make it possibe to ref PInput
  }
}
</script>